import React, { Suspense, lazy, useState, useCallback, useEffect } from "react"
import Helmet from "react-helmet"
import { Router, Link } from "@reach/router"

import InViewObserver from "../components/InViewObserver"
import Page_Footer from '../components/page_footer'
import Scrollbar from 'smooth-scrollbar';

import { TimelineMax, TweenMax, TweenLite, Linear, TextPlugin, ThrowPropsPlugin, EasePack, CSSPlugin, ScrollToPlugin, Power1, Power2, Power4, Expo, gsap } from 'gsap';

class Page extends React.Component {

  constructor(props) {
    super(props);
  }
  
  componentDidMount () {
    
    InViewObserver()

    // tag_list.forEach(element => console.log(element));

    // var tag_loop_a = new TimelineMax({ repeat: -1 });
    // var tag_loop_b = new TimelineMax({ repeat: -1 });
    // var tag_loop_c = new TimelineMax({ repeat: -1 });
    // var tag_loop_d = new TimelineMax({ repeat: -1 });
    // var tag_loop_e = new TimelineMax({ repeat: -1 });
    // var tag_loop_f = new TimelineMax({ repeat: -1 });

    // var tag_list = document.querySelectorAll('.tag_list');

    // tag_loop_a.staggerFromTo(tag_list[0], 20, { xPercent: 0, ease: Linear.easeNone }, { xPercent: -100, ease: Linear.easeNone });
    // tag_loop_b.staggerFromTo(tag_list[1], 20, { xPercent: 0, ease: Linear.easeNone }, { xPercent: -100, ease: Linear.easeNone });
    // tag_loop_c.staggerFromTo(tag_list[2], 20, { xPercent: 0, ease: Linear.easeNone }, { xPercent: -100, ease: Linear.easeNone });
    // tag_loop_d.staggerFromTo(tag_list[3], 20, { xPercent: 0, ease: Linear.easeNone }, { xPercent: -100, ease: Linear.easeNone });
    // tag_loop_e.staggerFromTo(tag_list[4], 20, { xPercent: 0, ease: Linear.easeNone }, { xPercent: -100, ease: Linear.easeNone });
    // tag_loop_f.staggerFromTo(tag_list[5], 20, { xPercent: 0, ease: Linear.easeNone }, { xPercent: -100, ease: Linear.easeNone });

    // var tag_wrap = document.querySelector('.tag_wrap');

    // tag_wrap.addEventListener('mouseenter', function () {
    //   tag_loop_a.timeScale(.5);
    //   tag_loop_b.timeScale(.5);
    //   tag_loop_c.timeScale(.5);
    //   tag_loop_d.timeScale(.5);
    //   tag_loop_e.timeScale(.5);
    //   tag_loop_f.timeScale(.5);
    // });
   
    // tag_wrap.addEventListener('mouseleave', function () {
    //   tag_loop_a.timeScale(1);
    //   tag_loop_b.timeScale(1);
    //   tag_loop_c.timeScale(1);
    //   tag_loop_d.timeScale(1);
    //   tag_loop_e.timeScale(1);
    //   tag_loop_f.timeScale(1);
    // });

    
    var body = document.querySelector('body');
    var tag_list = document.querySelectorAll('.tag_list');
    var tag_loop = document.querySelector('.tag_loop');
    var tag_item = document.querySelectorAll('.tag_item');
    var cursor = document.querySelector('.cursor');
    var cursor_visual = document.querySelector('.cursor_visual');

    cursor.classList.add('size_large');

		tag_list.forEach(function(el) {
      var tag_looping = new TimelineMax({ repeat: -1 });
			tag_looping.staggerFromTo(el, 20, { xPercent: 0, ease: Linear.easeNone }, { xPercent: -100, ease: Linear.easeNone });
      tag_loop.addEventListener('mouseenter', function () {
        tag_looping.timeScale(.5);
        body.classList.add('hide_cursor');
      });
      tag_loop.addEventListener('mouseleave', function () {
        tag_looping.timeScale(1);
        body.classList.remove('hide_cursor');
      });
		})

		tag_item.forEach(function(el) {
      var target_value = el.dataset.visual;

			el.addEventListener("touchmove",  function(){
        cursor.classList.add('tag_loop_active');
        cursor_visual.style.backgroundImage = `url(${target_value})`;
      });
			el.addEventListener("touchend",  function(){
        cursor.classList.remove('tag_loop_active');
			});
      
			el.addEventListener("mouseenter",  function(){
        cursor.classList.add('tag_loop_active');
        cursor_visual.style.backgroundImage = `url(${target_value})`;
			});
			el.addEventListener("mouseleave",  function(){
        cursor.classList.remove('tag_loop_active');
			});
    })
  }
 
  componentWillUnmount() {
    document.querySelector('.cursor').classList.remove('size_large');
  }


  render() {

    return (

      <div className="content_wrap">

        <div className="page_header">
          <h2 className="sub_title">社宅公共藝術宣言</h2>
          <h1 className="main_title">HOME TO ALL</h1>
        </div>

        <div className="page_content">

          <section className="row_area_s declaration_a">
            <div className="column_3">
              <div className="context">
                <h5>一場都市設計和城市文化的創新運動</h5>
                <p><b>給家一個新概念 / 市民共享共創共治的家園</b></p><p><b>跨越世代族群與階級 / 人類自然親賴共榮</b></p><p>北市社宅公共藝術，以此宣言，台北是所有人的家園，不遺落邊緣弱勢的人們；關心他們，與所有人腳下的這片土地。建立新生活，也創造新生活文化，認識彼此的過去，共同書寫新的未來。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s declaration_b row_gallery">
            <div className="column_6 onset_left_1 gallery_wrap">
              <figure className="figure size_max">
                <img src={require("../images/declaration/declaration_a_2.jpg")} alt=""/>
                <figcaption className="figcaption left">Assemble 團隊照（右）｜ Turner Prize Exhibition 2015（左）<br/>圖片提供/Courtesy of Assemble</figcaption>
              </figure>
              <figure className="figure size_m">
                <img src={require("../images/declaration/declaration_a_1.jpg")} alt=""/>
              </figure>
            </div>
          </section>

          <section className="row_area_s declaration_c">
            <div className="column_4 onset_left_4">
              <div className="context">
                <h6>家，是人類社會中最根本最基礎的社會組織，但在高度分子化的當代社會，遷徙流動更加頻繁，我們需要重新釐清家的概念形式與意義，公共藝術須要從各種文化社會心理的層面，去梳理各種不同人們的認知和情感，與生活共處的方式。</h6>
              </div>
            </div>
          </section>

          <section className="row_area_s declaration_d row_gallery">
            <div className="column_7 gallery_wrap">
              <figure className="figure size_max">
                <img src={require("../images/declaration/declaration_g_1.jpg")} alt=""/>
                <figcaption className="figcaption left">Superkilen<br/>圖片提供/禾磊藝術</figcaption>
              </figure>
              <figure className="figure size_s">
                <img src={require("../images/declaration/declaration_b_1.jpg")} alt=""/>
                <figcaption className="figcaption right">高雄水水<br/>圖片提供/辜達齊</figcaption>
              </figure>
            </div>
          </section>

          <section className="row_area_s declaration_e">
            <div className="column_3 onset_left_1">
              <div className="context">
                <p>因此，社宅公共藝術，以<strong>「關係美學」</strong>和<strong>「環境美學」</strong>作為雙美學核心，從整體都市環境，社會背景與社群關係的考量為出發點，來進行藝術的實踐。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s declaration_f">
            <div className="column_3 onset_left_1">
              <div className="context">
                <h5>公共藝術將成為催化劑，去促進輔助人們對應於環境以及相處模式的正向改變。</h5>
              </div>
            </div>
          </section>

          <section className="row_area_s declaration_f">
            <div className="column_5 onset_left_1">
              <div className="context">
                <h4>針對這場關鍵的城市文化創新運動，我們提出以下幾項追求的目標，作為臺北公共藝術打造文化家園的宣言：</h4>
              </div>
            </div>
          </section>

          <section className="row_area_s tag_loop_row row_break">
            <div className="tag_loop">
              <div className="tag_wrap">
                <div className="tag_list">
                  <div className="tag_item" data-visual={require("../images/declaration/declaration_a_1.jpg")}>社會設計翻轉社會機制</div>
                  <div className="tag_item" data-visual={require("../images/declaration/declaration_b_1.jpg")}>促進環境景觀和諧共融</div>
                  <div className="tag_item" data-visual={require("../images/declaration/declaration_c_1.jpg")}>耕耘社會環境美學教育</div>
                </div>
                <div className="tag_list">
                  <div className="tag_item" data-visual={require("../images/declaration/declaration_a_2.jpg")}>社會設計翻轉社會機制</div>
                  <div className="tag_item" data-visual={require("../images/declaration/declaration_b_2.jpg")}>促進環境景觀和諧共融</div>
                  <div className="tag_item" data-visual={require("../images/declaration/declaration_c_2.jpg")}>耕耘社會環境美學教育</div>
                </div>
              </div>
              <div className="tag_wrap">
                <div className="tag_list">
                  <div className="tag_item" data-visual={require("../images/declaration/declaration_e_2.jpg")}>結合社會正義弱勢照顧</div>
                  <div className="tag_item" data-visual={require("../images/declaration/declaration_d_1.jpg")}>尊重多元記憶情感脈絡</div>
                  <div className="tag_item" data-visual={require("../images/declaration/declaration_f_2.jpg")}>貼近日常民俗文化脈動</div>
                  <div className="tag_item" data-visual={require("../images/declaration/declaration_g_1.jpg")}>塑造都市文化的生活圈</div>
                </div>
                <div className="tag_list">
                  <div className="tag_item" data-visual={require("../images/declaration/declaration_c_1.jpg")}>耕耘社會環境美學教育</div>
                  <div className="tag_item" data-visual={require("../images/declaration/declaration_d_2.jpg")}>尊重多元記憶情感脈絡</div>
                  <div className="tag_item" data-visual={require("../images/declaration/declaration_b_1.jpg")}>促進環境景觀和諧共融</div>
                  <div className="tag_item" data-visual={require("../images/declaration/declaration_g_2.jpg")}>塑造都市文化的生活圈</div>
                </div>
              </div>
              <div className="tag_wrap">
                <div className="tag_list">
                  <div className="tag_item" data-visual={require("../images/declaration/declaration_f_1.jpg")}>貼近日常民俗文化脈動</div>
                  <div className="tag_item" data-visual={require("../images/declaration/declaration_d_2.jpg")}>尊重多元記憶情感脈絡</div>
                  <div className="tag_item" data-visual={require("../images/declaration/declaration_e_2.jpg")}>結合社會正義弱勢照顧</div>
                  <div className="tag_item" data-visual={require("../images/declaration/declaration_g_2.jpg")}>塑造都市文化的生活圈</div>
                  <div className="tag_item" data-visual={require("../images/declaration/declaration_b_2.jpg")}>促進環境景觀和諧共融</div>
                </div>
                <div className="tag_list">
                  <div className="tag_item" data-visual={require("../images/declaration/declaration_f_2.jpg")}>貼近日常民俗文化脈動</div>
                  <div className="tag_item" data-visual={require("../images/declaration/declaration_d_2.jpg")}>尊重多元記憶情感脈絡</div>
                  <div className="tag_item" data-visual={require("../images/declaration/declaration_e_1.jpg")}>結合社會正義弱勢照顧</div>
                  <div className="tag_item" data-visual={require("../images/declaration/declaration_g_1.jpg")}>塑造都市文化的生活圈</div>
                  <div className="tag_item" data-visual={require("../images/declaration/declaration_b_1.jpg")}>促進環境景觀和諧共融</div>
                </div>
              </div>
            </div>
          </section>

        </div>


        <div className="page_footer">
          <Link to="/discourse" className="next_page">
            <div className="next_title">社宅公共藝術宣言</div>
            <div className="next_owner">HOME TO ALL</div>
          </Link>
        </div>

      </div>

    )

  }

}


export default Page

